//SHOW COMPANY
export const SHOW_COMPANY_BYUSER_REQUEST = 'SHOW_COMPANY_BYUSER_REQUEST'
export const SHOW_COMPANY_BYUSER_SUCCESS = 'SHOW_COMPANY_BYUSER_SUCCESS'
export const SHOW_COMPANY_BYUSER_FAIL = 'SHOW_COMPANY_BYUSER_FAIL'

//UPDATE COMPANY
export const UP_COMPANY_BYUSER_REQUEST = 'UP_COMPANY_BYUSER_REQUEST'
export const UP_COMPANY_BYUSER_SUCCESS = 'UP_COMPANY_BYUSER_SUCCESS'
export const UP_COMPANY_BYUSER_FAIL = 'UP_COMPANY_BYUSER_FAIL'

//UPDATE ALL COMPANY
export const SHOW_ALL_COMPANY_BYUSER_REQUEST = 'SHOW_ALL_COMPANY_BYUSER_REQUEST'
export const SHOW_ALL_COMPANY_BYUSER_SUCCESS = 'SHOW_ALL_COMPANY_BYUSER_SUCCESS'
export const SHOW_ALL_COMPANY_BYUSER_FAIL = 'SHOW_ALL_COMPANY_BYUSER_FAIL'
