export const date = [
    { name: 'janv', id: '01' },
    { name: 'févr', id: '02' },
    { name: 'mars', id: '03' },
    { name: 'avr', id: '04' },
    { name: 'mai', id: '05' },
    { name: 'juin', id: '06' },
    { name: 'juil', id: '07' },
    { name: 'août', id: '08' },
    { name: 'sept', id: '09' },
    { name: 'oct', id: '10' },
    { name: 'nov', id: '11' },
    { name: 'déc', id: '12' },
]