//show service
export const SHOW_SERVICE_REQUEST='SHOW_SERVICE_REQUEST'
export const SHOW_SERVICE_SUCCESS='SHOW_SERVICE_SUCCESS'
export const SHOW_SERVICE_FAIL='SHOW_SERVICE_FAIL'

//add Service
export const ADD_SERVICE_REQUEST='ADD_SERVICE_REQUEST'
export const ADD_SERVICE_SUCCESS='ADD_SERVICE_SUCCESS'
export const ADD_SERVICE_FAIL='ADD_SERVICE_FAIL'

//delate Service
export const DEL_SERVICE_REQUEST='DEL_SERVICE_REQUEST'
export const DEL_SERVICE_SUCCESS='DEL_SERVICE_SUCCESS'
export const DEL_SERVICE_FAIL='DEL_SERVICE_FAIL'

//update Service
export const UPD_SERVICE_REQUEST='UPD_SERVICE_REQUEST'
export const UPD_SERVICE_SUCCESS='UPD_SERVICE_SUCCESS'
export const UPD_SERVICE_FAIL='UPD_SERVICE_FAIL'
