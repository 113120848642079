//SHOW USER
export const SHOW_USER_REQUEST = 'SHOW_USER_REQUEST'
export const SHOW_USER_SUCCESS = 'SHOW_USER_SUCCESS'
export const SHOW_USER_FAIL = 'SHOW_USER_FAIL'

//SHOW USER
export const UPD_USER_REQUEST = 'UPD_USER_REQUEST'
export const UPD_USER_SUCCESS = 'UPD_USER_SUCCESS'
export const UPD_USER_FAIL = 'UPD_USER_FAIL'

//update abonmon
export const UPD_ABON_REQUEST = 'UPD_ABON_REQUEST'
export const UPD_ABON_SUCCESS = 'UPD_ABON_SUCCESS'
export const UPD_ABON_FAIL = 'UPD_ABON_FAIL'

export const USER_ETATS_UPDATE_REQUEST = 'USER_ETATS_UPDATE_REQUEST'
export const USER_ETATS_UPDATE_SUCESS = 'USER_ETATS_UPDATE_SUCESS'
export const USER_ETATS_UPDATE_FAIL = 'USER_ETATS_UPDATE_FAIL'