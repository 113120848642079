//show Client
export const SHOW_CLIENT_REQUEST = 'SHOW_CLIENT_REQUEST'
export const SHOW_CLIENT_SUCCESS = 'SHOW_CLIENT_SUCCESS'
export const SHOW_CLIENT_FAIL = 'SHOW_CLIENT_FAIL'

//add Client
export const ADD_CLIENT_REQUEST = 'ADD_CLIENT_REQUEST'
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS'
export const ADD_CLIENT_FAIL = 'ADD_CLIENT_FAIL'

//delate Client
export const DEL_CLIENT_REQUEST = 'DEL_CLIENT_REQUEST'
export const DEL_CLIENT_SUCCESS = 'DEL_CLIENT_SUCCESS'
export const DEL_CLIENT_FAIL = 'DEL_CLIENT_FAIL'

//update Client
export const UPD_CLIENT_REQUEST = 'UPD_CLIENT_REQUEST'
export const UPD_CLIENT_SUCCESS = 'UPD_CLIENT_SUCCESS'
export const UPD_CLIENT_FAIL = 'UPD_CLIENT_FAIL'

// show invoice by CLIENT
export const SHOW_INVOICE_BYCLIENT_REQUEST = 'SHOW_INVOICE_BYCLIENT_REQUEST'
export const SHOW_INVOICE_BYCLIENT_SUCCESS = 'SHOW_INVOICE_BYCLIENT_SUCCESS'
export const SHOW_INVOICE_BYCLIENT_FAIL = 'SHOW_INVOICE_BYCLIENT_FAIL'

export const SHOW_CLIENT_ETATS_UPDATE_REQUEST = 'SHOW_CLIENT_ETATS_UPDATE_REQUEST'
export const SHOW_CLIENT_ETATS_UPDATE_SUCESS = 'SHOW_CLIENT_ETATS_UPDATE_SUCESS'
export const SHOW_CLIENT_ETATS_UPDATE_FAIL = 'SHOW_CLIENT_ETATS_UPDATE_FAIL'
