//show Material
export const SHOW_MATERIAL_REQUEST='SHOW_MATERIAL_REQUEST'
export const SHOW_MATERIAL_SUCCESS='SHOW_MATERIAL_SUCCESS'
export const SHOW_MATERIAL_FAIL='SHOW_MATERIAL_FAIL'

//add Material
export const ADD_MATERIA_REQUEST='ADD_MATERIA_REQUEST'
export const ADD_MATERIA_SUCCESS='ADD_MATERIA_SUCCESS'
export const ADD_MATERIA_FAIL='ADD_MATERIA_FAIL'

//delate Material
export const DEL_MATERIA_REQUEST='DEL_MATERIA_REQUEST'
export const DEL_MATERIA_SUCCESS='DEL_MATERIA_SUCCESS'
export const DEL_MATERIA_FAIL='DEL_MATERIA_FAIL'

//update Material
export const UPD_MATERIA_REQUEST='UPD_MATERIA_REQUEST'
export const UPD_MATERIA_SUCCESS='UPD_MATERIA_SUCCESS'
export const UPD_MATERIA_FAIL='UPD_MATERIA_FAIL'
