export const ADD_INVOICE_REQUEST = 'ADD_INVOICE_REQUEST'
export const ADD_INVOICE_SUCESS = 'ADD_INVOICE_SUCESS'
export const ADD_INVOICE_FAIL = 'ADD_INVOICE_FAIL'

//delate invoice
export const DEL_INVOICE_REQUEST = 'DEL_INVOICE_REQUEST'
export const DEL_INVOICE_SUCESS = 'DEL_INVOICE_SUCESS'
export const DEL_INVOICE_FAIL = 'DEL_INVOICE_FAIL'
//delate invoice by client
export const DEL_INVOICE_CLIENT_REQUEST = 'DEL_INVOICE_CLIENT_REQUEST'
export const DEL_INVOICE_CLIENT_SUCESS = 'DEL_INVOICE_CLIENT_SUCESS'
export const DEL_INVOICE_CLIENT_FAIL = 'DEL_INVOICE_CLIENT_FAIL'